import * as React from "react";
import IMG_brand from "../images/brand.png";
import { FacebookRounded, Instagram, Twitter } from "@mui/icons-material";
import { Button, TextField } from "@mui/material";

// markup
const IndexPage = () => {
  const handleSubscribe = () => {};

  return (
    <main className="">
      <div className="w-full bg-white px-6 lg:px-24 py-4 flex items-center justify-between">
        <img src={IMG_brand} alt="Eastward Legal brand" className="h-14" />
        <div className="flex items-center">
          <a href="#" target="_blank" className="mx-2">
            <FacebookRounded />
          </a>
          <a href="#" target="_blank" className="mx-2">
            <Twitter />
          </a>
          <a href="#" target="_blank" className="mx-2">
            <Instagram />
          </a>
        </div>
      </div>

      <div className="w-full flex flex-col lg:flex-row px-6 lg:px-24 py-8">
        <div className="w-full lg:w-2/3 flex flex-col items-center lg:items-start">
          <div className="px-4 py-2 bg-green-600 text-white text-xl lg:text-4xl font-bold rounded-md">
            <p>Coming Soon!</p>
          </div>

          <h1 className="py-4 text-gray-700 text-center lg:text-left font-bold font-sans text-4xl lg:text-8xl tracking-wide">
            Eastward Legal Services
          </h1>

          <div className="py-8 w-full text-center lg:text-left">
            <p className="text-base lg:text-2xl text-gray-700 font-sans">
              Get notified when we launch.
            </p>
            <div className="my-4 flex items-center shadow-inner rounded-md bg-gray-100 lg:w-1/2">
              <TextField
                id="outlined-basic"
                label="Enter your email here"
                fullWidth
                variant="outlined"
                placeholder="Enter your email"
                inputProps={{
                  className: "border-0",
                }}
              />
              <div className="px-3">
                <Button
                  variant="text"
                  className="mx-3"
                  color="success"
                  onClick={handleSubscribe}
                >
                  Subscribe
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/3">
          <div className="w-full rounded-md border border-solid border-gray-200 bg-gray-100 px-3 lg:px-8 py-5 lg:py-10">
            <h2 className="text-gray-800 text-xl lg:text-3xl font-bold text-center">
              Contact Us
            </h2>
            <p className="text-gray-700 text-center">For help and/or support</p>

            <form
              className="py-10"
              name="contact"
              method="POST"
              data-netlify="true"
            >
              <div className="my-3 w-full shadow-inner bg-white">
                <TextField
                  label="Your Name"
                  size="small"
                  fullWidth
                  variant="outlined"
                  type="text"
                  color="success"
                  name="name"
                />
              </div>
              <div className="my-3 w-full shadow-inner bg-white">
                <TextField
                  label="Your Email"
                  size="small"
                  fullWidth
                  variant="outlined"
                  type="email"
                  color="success"
                  name="email"
                />
              </div>
              <div className="my-3 w-full shadow-inner bg-white">
                <TextField
                  label="Your Inquiry"
                  size="small"
                  fullWidth
                  variant="outlined"
                  type="text"
                  multiline
                  minRows={3}
                  maxRows={6}
                  color="success"
                  name="message"
                />
              </div>

              <a className="px-16 py-3 bg-green-600 text-white font-bold tracking-wide rounded-md shadow-md block mx-auto text-center">
                Send Message
              </a>
            </form>
          </div>
        </div>
      </div>
      <div className="py-4 bg-gray-200 text-gray-700 shadow-inner flex flex-wrap items-center">
        <div className="w-full lg:w-1/3 px-4 text-center lg:text-left my-5 lg:my-0">
          <p className="text-sm font-bold uppercase mb-2">CONTACT</p>
          <p>
            Email:{" "}
            <a href="mailto:direct@eastwardlegal.com">
              direct@eastwardlegal.com
            </a>
          </p>
          <p>
            Phone: <a href="tel:+2347032859644">(+234) 703 285 9644</a>
          </p>
        </div>
        <div className="w-full lg:w-1/3 px-4 text-center">
          &copy; {new Date().getFullYear()} Eastward Legal Services
        </div>

        <div className="w-full lg:w-1/3 px-4 text-center lg:text-right my-5 lg:my-0">
          <p className="text-sm font-bold uppercase mb-2">ADDRESS</p>
          <p>
            Suite A09, Panasonic Center, 2, Michika Street, Garki II, Abuja FCT.
          </p>
        </div>
      </div>
    </main>
  );
};

export default IndexPage;
